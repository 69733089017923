<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Inventory</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <DynamicTable
            v-model:rows="rows"
            v-model:items="items"
            :fix-height="true"
            :show-order="false"
            :show-pin="false"
            type="products"
          />
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import DynamicTable from "../lists/DynamicTable";
import { useStore } from "vuex";
const bootstrap = require("bootstrap");

export default {
  name: "StockLocationModal",
  props: {
    id: String,
  },
  data: function () {
    return {
      store: useStore(),
      loadAddBtn: false,
      rows: [
        {
          name: "Location",
          key: "slot_number",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Stock",
          key: "quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "First added at",
          key: "first_added_date",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Last added at",
          key: "last_added_date",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Expiry date",
          key: "expiry_date",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Serial number",
          key: "serial_number",
          type: "string",
          show: true,
          editable: false,
        },
      ],
      items: [],
      myModal: undefined,
    };
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
      this.load();
    },
    load() {
      let e = "";
      if (this.warehouse) {
        e = "?warehouse=" + this.warehouse;
      }
      http
        .fetch("/products/warehouse-stock-locations/" + this.id + e)
        .then((data) => {
          this.items = data.items;
        });
    },
  },
  components: { DynamicTable },
};
</script>
