<template>
  <span class="regular-12 text-black text-start">
    <button class="btn btn-secondary btn-sm" v-on:click="showModal">
      <BaseIcon name="map" color="#4B506D" />
    </button>
  </span>
  <StockLocationModal
    ref="modal"
    :id="main ? id : variantId > 0 ? variantId : ''"
  />
</template>

<script>
import { defineAsyncComponent } from "vue";
import StockLocationModal from "../../modals/StockLocationModal.vue";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));
export default {
  name: "StockLocation",
  components: { StockLocationModal, BaseIcon },
  props: {
    id: Number,
    main: Number,
    type: String,
    row: Object,
    modelValue: String,
    variantId: Number,
  },
  data() {
    return {};
  },
  methods: {
    showModal() {
      this.$refs.modal.showModal();
    },
  },
};
</script>
